<template>
  <div class="main">
    <branchCards addBranch="false" actionPrefix=""></branchCards>
    <Legal></Legal>
  </div>
</template>

<script>
import branchCards from "@/components/layout/branchCards";
import Legal from "@/components/layout/Legal";

export default {
  components: { branchCards, Legal },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Áreas de Trabajo");
  },
  name: "Work Area"
};
</script>
